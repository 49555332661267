import {
  ArrowPathIcon,
  ClipboardIcon,
  PencilIcon, ShareIcon
} from "@heroicons/react/24/solid";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import ConfirmModalComponent from "../../components/ConfirmModal";
import DropdownComponent from "../../components/DropdownComponent";
import OrderService from "../../services/order.service";
import OrderItem from "./OrderItem";

function ViewOrder() {
  const notify = useOutletContext();
  const [servicesList, setServicesList] = useState([]);
  const [customer, setCustomer] = useState();
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [orderActivity, setOrderActivity] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState({});
  let { order } = useParams();
  const getInvoice = () => {
    let invoice =
      `Hi ${orderDetails.customer.firstName},

Your bill for order #${orderDetails.number} is as follows:

` +
      Object.entries(orderDetails.services)
        .map(
          ([key, value], index) =>
            `${index + 1}. ${getServiceDetail(key)[0]?.name} x${value} - ₦${(
              (getServiceDetail(key)[0]?.cost * value) /
              100
            ).toLocaleString()}
`
        )
        .join("") +
      `
Pickup - ₦${(orderDetails.drop.fee / 100).toLocaleString()}
Delivery - ₦${(orderDetails.return.fee / 100).toLocaleString()}
*Total - ₦${(orderDetails.amount / 100).toLocaleString()}*

Please make payment to:
Account Number: 9880324115
Bank Name: Paystack-Titan
Account Name: Sneaklinmulti/Laundry`;
    return invoice;
  };

  const getOrder = () => {
    setOrderLoading(true);
    OrderService.getOrder(order)
      .then((resp) => {
        setOrderDetails(resp);
        setCustomer(resp.customer);

        setOrderLoading(false);
      })
      .catch((err) => {
        console.log(err);
        notify("success", "Error", "Theres an error");
        setOrderLoading(false);
      });
  };

  const getOrderActivity = () => {
    setOrderLoading(true);
    OrderService.getOrderActivity(order)
      .then((resp) => {
        setOrderActivity(resp.activity);

        setOrderLoading(false);
      })
      .catch((err) => {
        console.log(err);
        notify("success", "Error", "Theres an error");
        setOrderLoading(false);
      });
  };

  useEffect(() => {
    getOrder();
    getOrderActivity();
  }, []);
  const getServiceDetail = (code) => {
    let service = servicesList.filter((f) => f.code === code);
    return service;
  };
  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServicesList(resp.services);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getServices();
  }, []);
  return (
    <>
      {orderDetails ? (
        <div className="">
          <div className="my-4 flex items-start	space-x-4 flex-wrap">
            <BackButton />
            <div className="flex-1">
              <h1 className="text-2xl">Order #{orderDetails.number}</h1>
              <small className="text-gray-600">
                {moment(orderDetails.createdAt).isBefore(
                  Date.now() - 3 * 24 * 3600 * 1000
                )
                  ? moment(orderDetails.createdAt).format(
                      "dddd, Do MMM. YYYY, h:mm:ss a"
                    )
                  : moment(orderDetails.createdAt).fromNow()}
                
              </small>
            </div>
            <div className="flex">
              <Link
                to={"/dashboard/order/" + order + "/edit"}
                className="hover:bg-gray-100 px-3 py-2 flex items-center space-x-1"
              >
                <PencilIcon className="h-4 w-4" /> <span>Edit Order</span>
              </Link>
            </div>
          </div>
          <div className="flex flex-col xl:flex-row w-full lg:w-3/4 2xl:w-2/3 mx-auto gap-2 justify-center">
            <div className="w-full xl:w-2/3 flex flex-col space-y-2">
              <div className="border border-gray-300 p-5">
                <div className="flex flex-col mb-4">
                  <h1 className="text-xl">Services</h1>
                  <small className="text-gray-600">
                    {orderDetails.drop.type === "walk-in"
                      ? "Walk In "
                      : "Pickup "}
                    - {format(new Date(orderDetails.drop.date), "dd MMM. yy")} |{" "}
                    {orderDetails.return.type === "walk-in"
                      ? "Walk In "
                      : "Delivery "}
                    - {format(new Date(orderDetails.return.date), "dd MMM. yy")}
                  </small>
                </div>
                <table className="table-auto w-full border-separate border-spacing-2">
                  <tbody>
                    {Object.entries(orderDetails.services).map(
                      ([key, value]) => (
                        <tr key={key} className="py-2">
                          <td>
                            <span className="font-medium">
                              {getServiceDetail(key)[0]?.name}
                            </span>
                          </td>
                          <td>{value}</td>
                          <td className="text-right">
                            &#8358;
                            {(
                              (getServiceDetail(key)[0]?.cost * value) /
                              100
                            ).toLocaleString()}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <OrderItem />
            </div>
            <div className="w-full xl:w-1/3 flex flex-col space-y-2">
              <div className="border border-gray-300 p-5">
                {customer ? (
                  <div className="">
                    <h1 className="text-lg font-medium">
                      {customer.firstName} {customer.lastName}
                    </h1>
                    <p>{customer.phone}</p>
                    <small>{customer.email}</small>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                  </div>
                )}
              </div>
              <div className="border border-gray-300 p-5">
                <div className="flex mb-3 items-center space-x-3">
                  <h1 className="text-xl">
                    {orderDetails.paymentMethod === "" ? "Unpaid" : "Paid"}
                  </h1>

                  <span className=" text-xs font-semibold py-1 px-2 uppercase rounded text-gray-600 bg-gray-200 uppercase">
                    {orderDetails.paymentMethod === "" && "Later"}
                    {orderDetails.paymentMethod === "cash" && "Cash"}
                    {orderDetails.paymentMethod === "card" && "Card"}
                    {orderDetails.paymentMethod === "transfer" && "Transfer"}
                  </span>
                </div>
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td className="text-right">
                        &#8358;
                        {Object.entries(orderDetails.services)
                          .map(
                            ([key, value]) =>
                              (getServiceDetail(key)[0]?.cost * value) / 100
                          )
                          .reduce((accumulator, object) => {
                            return accumulator + object;
                          }, 0)
                          .toLocaleString()}
                      </td>
                    </tr>
                    {orderDetails.drop.type === "delivery" && (
                      <tr>
                        <td>Pickup</td>
                        <td className="text-right">
                          &#8358;
                          {(orderDetails.drop.fee / 100 || 0).toLocaleString()}
                        </td>
                      </tr>
                    )}
                    {orderDetails.return.type === "delivery" && (
                      <tr>
                        <td>Delivery</td>
                        <td className="text-right">
                          &#8358;
                          {(
                            orderDetails.return.fee / 100 || 0
                          ).toLocaleString()}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Discount</td>
                      <td className="text-right">
                        {orderDetails.discount || 0}%
                      </td>
                    </tr>
                    <tr>
                      <td>Tax</td>
                      <td className="text-right">0%</td>
                    </tr>
                    <tr>
                      <td className="font-bold">Total</td>
                      <td className="text-right font-bold">
                        &#8358;
                        {(orderDetails.amount / 100).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="border border-gray-300 p-5">
                <h4 className="font-bold text-md">Order updates</h4>
                <ul className="my-3">
                  {orderActivity?.map((activity, key) => (
                    <li
                      key={key}
                      className="border-l-2 border-gray-300 first:border-green-600 pl-4 py-2 first:text-green-700 first:font-semibold"
                    >
                      <h4 className="capitalize">
                        {activity.action.replace("_", " ")}
                      </h4>
                      <small className="text-gray-800">
                        {moment(activity.timestamp).isBefore(
                          Date.now() - 3 * 24 * 3600 * 1000
                        )
                          ? moment(activity.timestamp).format(
                              "dddd, Do MMM. YYYY, h:mm:ss a"
                            )
                          : moment(activity.timestamp).fromNow()}
                      </small>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {!orderDetails.paymentMethod && (
            <div className="w-full lg:w-3/4 2xl:w-2/3 mx-auto mt-3 flex items-center justify-end gap-2">
              {/* <button
              className="px-3 py-2 hover:bg-gray-100 text-black focus:shadow-outline focus:outline-none"
              onClick={() => setShowInvoice(true)}
            >
              <ReceiptPercentIcon className="h-5 w-5" />
            </button> */}
              <button
                onClick={() => {
                  navigator.clipboard
                    .writeText(getInvoice())
                    .then((x) => alert("Bill copied to clipboard."));
                }}
                className="px-3 py-2 bg-black flex gap-2 items-center hover:bg-gray-500 text-white focus:shadow-outline focus:outline-none"
              >
                <ClipboardIcon className="h-4 w-4" /> Copy Bill
              </button>
              <button
                onClick={() => {
                  window.open(
                    "https://wa.me/" +
                      orderDetails.customer.phone +
                      "?text=" +
                      getInvoice(),
                    "_blank"
                  );
                }}
                className="px-3 py-2 bg-black flex gap-2 items-center hover:bg-gray-500 text-white focus:shadow-outline focus:outline-none"
              >
                <ShareIcon className="h-4 w-4" /> Send Bill
              </button>
              <DropdownComponent
                order={orderDetails}
                className="relative bg-black text-white"
                setConfirm={setOpenConfirmModal}
                setConfirmOrder={setConfirmOrder}
              />
            </div>
          )}

          {openConfirmModal && (
            <ConfirmModalComponent
              order={confirmOrder}
              getOrders={getOrder}
              setConfirm={setOpenConfirmModal}
            />
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <ArrowPathIcon className="h-10 w-10 mx-auto animate-spin" />
        </div>
      )}
    </>
  );
}
export default ViewOrder;
